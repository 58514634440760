import {
    Action,
	Module,
	Mutation,
	VuexModule,
	getModule,
} from "vuex-module-decorators";
import { IRootState, store } from "@/store";
import { Module as Mod } from "vuex";
import { AlertModule } from "./Alert";

export enum AudioDeviceKeys {
    callOutputDevice = "callDeviceId",
    callOutputDeviceName = "callDeviceName",
    ringtoneOutputDevice = "ringtoneDeviceId",
    ringtoneOutputDeviceName = "ringtoneDeviceName",
    ringtoneOutputDeviceVolume = "ringtoneVolume",
    callInputDevice = "AudioSrcId",
    callInputDeviceName = "AudioSrcName",
}

export interface IOutputDevice {
    callOutputDevice: string | null;
    callOutputDeviceName: string | null;
    inputDevice: string | null;
    inputDeviceName: string | null;
    callTestFailed: boolean;
    ringtoneOutputDevice: string | null;
    ringtoneOutputDeviceName: string | null;
    ringtoneOutputDeviceVolume: number;
    ringTestFailed: boolean;
    showOutputDeviceSettings: boolean;
    testPlayed: boolean;
}
@Module({ dynamic: true, namespaced: true, store, name: "outputDevice" })
export class OutputDevice extends VuexModule implements IOutputDevice {
	constructor(module: Mod<ThisType<IOutputDevice>, IRootState>) {
		super(module);
        const callDeviceId = localStorage.getItem(AudioDeviceKeys.callOutputDevice);
        const callDeviceName = localStorage.getItem(AudioDeviceKeys.callOutputDeviceName);
        this.setCallOutputDevice({ deviceId: callDeviceId, deviceName: callDeviceName });
        const ringtoneDeviceId = localStorage.getItem(AudioDeviceKeys.ringtoneOutputDevice);
        const ringtoneDeviceName = localStorage.getItem(AudioDeviceKeys.ringtoneOutputDeviceName);
        const ringtoneDeviceVolume = localStorage.getItem(AudioDeviceKeys.ringtoneOutputDeviceVolume);
        this.setRingtoneOutputDevice({
            deviceId: ringtoneDeviceId,
            deviceName: ringtoneDeviceName,
            deviceVolume: ringtoneDeviceVolume,
        });
        const inputDeviceId = localStorage.getItem(AudioDeviceKeys.callInputDevice);
        const inputDeviceName = localStorage.getItem(AudioDeviceKeys.callInputDeviceName);
        this.setInputAudioDevice({
            deviceId: inputDeviceId,
            deviceName: inputDeviceName,
        })
	}
    callOutputDevice: string | null = null;
    callOutputDeviceName: string | null = null;
    callTestFailed = false;
    ringtoneOutputDevice: string | null = null;
    ringtoneOutputDeviceName: string | null = null;
    ringtoneOutputDeviceVolume = 100;
    ringTestFailed = false;
    showOutputDeviceSettings = false;
    testPlayed = false;
    inputDevice: string | null;
    inputDeviceName: string | null;

    get CallOutputDevice() {
        return this.callOutputDevice
    }

    get CallOutputDeviceName() {
        return this.callOutputDeviceName
    }

    get CallTestFailed() {
        return this.callTestFailed;
    }

    get RingtoneOutputDevice() {
        return this.ringtoneOutputDevice
    }

    get RingtoneOutputDeviceName() {
        return this.ringtoneOutputDeviceName
    }

    get RingtoneOutputDeviceVolume() {
        return this.ringtoneOutputDeviceVolume
    }

    get RingTestFailed() {
        return this.ringTestFailed;
    }

    get CallInputDevice() {
        return this.inputDevice
    }

    get CallInputDeviceName() {
        return this.inputDeviceName
    }

    get ShowOutputDeviceSettings() {
        return this.showOutputDeviceSettings;
    }

    @Mutation
	setCallOutputDevice({ deviceId, deviceName, alert=false }:
        { deviceId: string | null, deviceName: string | null, alert?: boolean }
    ) {
        if(deviceId && deviceName) {
            localStorage.setItem(AudioDeviceKeys.callOutputDevice, deviceId);
            localStorage.setItem(AudioDeviceKeys.callOutputDeviceName, deviceName);
            this.callOutputDevice = deviceId;
            this.callOutputDeviceName = deviceName;
            this.callTestFailed = false;
            if(alert)
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "Se ha actualizado el dispositivo de salida de audio para llamada",
                    type: "success",
                });
        } else
            if(alert)
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "No se ha podido actualizar el dispositivo de salida de audio para llamada",
                    type: "error",
                });
	}

    @Mutation
    setCallTestFailed(status: boolean) {
        this.callTestFailed = status
    }

    @Mutation
	setRingtoneOutputDevice({ deviceId, deviceName, deviceVolume, alert=false }: 
        { deviceId: string | null, deviceName: string | null, deviceVolume: string | null, alert?: boolean}
    ) {
        if(deviceId && deviceName) {
            localStorage.setItem(AudioDeviceKeys.ringtoneOutputDevice, deviceId);
            localStorage.setItem(AudioDeviceKeys.ringtoneOutputDeviceName, deviceName);
            this.ringtoneOutputDevice = deviceId;
            this.ringtoneOutputDeviceName = deviceName;
            this.ringTestFailed = false;
            if(alert)
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "Se ha actualizado el dispositivo de salida de audio para tono de llamada",
                    type: "success",
                });
        } else
            if(alert)
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "No se ha podido actualizar el dispositivo de salida de audio para tono de llamada",
                    type: "error",
                });

        if(deviceVolume) {
            localStorage.setItem(AudioDeviceKeys.ringtoneOutputDeviceVolume, deviceVolume);
            this.ringtoneOutputDeviceVolume = parseInt(deviceVolume) ?? 100;
        }
	}

    @Mutation
	setInputAudioDevice({ deviceId, deviceName, alert=false }: 
        { deviceId: string | null, deviceName: string | null, alert?: boolean}
    ) {
        if(deviceId && deviceName) {
            localStorage.setItem(AudioDeviceKeys.callInputDevice, deviceId);
            localStorage.setItem(AudioDeviceKeys.callInputDeviceName, deviceName);
            this.inputDevice = deviceId;
            this.inputDeviceName = deviceName;
            if(alert)
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "Se ha actualizado el dispositivo de entrada de audio",
                    type: "success",
                });
        } else
            if(alert)
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "No se ha podido actualizar el dispositivo de entrada de audio",
                    type: "error",
                });
	}

    @Mutation
    setRingTestFailed(status: boolean) {
        this.ringTestFailed = status
    }

    @Mutation
    setShowOutputDeviceSettings(value: boolean) {
        this.showOutputDeviceSettings = value
    }

    @Mutation
    setTestPlayed(played: boolean) {
        this.testPlayed = played
    }

    @Action
    testOutputDevice() {
        if(!this.testPlayed) {
            const audio = new Audio('/audio/output_device_test.mp3');
            const callDeviceId = OutputDeviceModule.CallOutputDevice;
            //@ts-ignore
            audio.setSinkId(callDeviceId ?? "default").then(() => {
                audio.volume = 0;
                audio.play();
                setTimeout(() => {
                    audio.pause();
                    OutputDeviceModule.setCallTestFailed(false);
                    console.log("Dispositivo de salida de audio de llamada configurado correctamente");
                }, 2000);
            }).catch(() => {
                OutputDeviceModule.setCallTestFailed(true);
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "Por favor verificar la configuración de salida de audio para llamadas",
                    type: "error",
                });
            })
    
            const ringtoneAudio = new Audio('/audio/output_device_test.mp3');
            const ringtoneDeviceId = OutputDeviceModule.RingtoneOutputDevice;
            //@ts-ignore
            ringtoneAudio.setSinkId(ringtoneDeviceId ?? "default").then(() => {
                ringtoneAudio.volume = 0;
                ringtoneAudio.play();
                setTimeout(() => {
                    ringtoneAudio.pause();
                    OutputDeviceModule.setRingTestFailed(false);
                    console.log("Dispositivo de salida de tono de llamada configurado correctamente");
                }, 2000);
            }).catch(() => {
                OutputDeviceModule.setRingTestFailed(true);
                AlertModule.SOCKET_PUSH_NOTIFICATION({
                    text: "Por favor verificar la configuración de salida de audio para tono de llamada",
                    type: "error",
                });
            })
            this.setTestPlayed(true)
        }
    }
}
export const OutputDeviceModule = getModule(OutputDevice);
